var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full flex flex-col overflow-hidden"},[(!_vm.mobile)?_c('div',{staticClass:"h-12 flex-none flex items-center mb-2"},[_c('div',{staticClass:"module-title self-center"},[_vm._v("SMU")]),_c('div',{staticClass:"flex ml-auto"},[_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"pill-gray h-full flex flex-col justify-center items-center",on:{"click":function($event){return _vm.toggleSlSelectedItemsAll()}}},[_c('span',{class:{ 'text-aux': _vm.all }},[_vm._v("ALL")])])]),_vm._l((_vm.legendColors),function(pill,k){return _c('div',{key:k,staticClass:"ml-2"},[_c('div',{staticClass:"pill-gray",style:(_vm.getOpacity(pill, k)),on:{"click":function($event){return _vm.toggleSlSelectedItems(k)}}},[_vm._v(" "+_vm._s(pill.name)+" ")])])})],2)]):_vm._e(),_c('div',{staticClass:"flex-1 overflow-hidden relative flex flex-col min-h-0"},[(_vm.loading)?_c('div',{staticClass:"absolute bg-box-80 z-1flex items-center mb-40 h-full w-full top-0 rounded-lg left-0"},[_c('loader',{attrs:{"loading":_vm.loading}})],1):_vm._e(),(_vm.mobile)?_c('div',{staticClass:"overflow-auto flex-1 pb-8 no-scrollbar"},[_vm._l((_vm.smuFiltered),function(el){return _c('PipelineSMUMobile',{key:el.id +
          '' +
          _vm.removeCharacters(el.id_sl) +
          '' +
          _vm.removeCharacters(el.name),attrs:{"data":el,"desktop":true}})}),(_vm.smuFiltered.length == 0)?_c('div',{staticClass:"h-full flex flex-col justify-center items-center"},[_c('span',{staticClass:"text-font-gray text-xs"},[_vm._v("No SL Selected")])]):_vm._e()],2):[(_vm.smuFiltered.length > 0)?_c('transition-group',{staticClass:"h-full overflow-y-auto pb-10",attrs:{"name":"fade","tag":"div"}},_vm._l((_vm.smuFiltered),function(el){return _c('div',{key:el.id +
            '' +
            _vm.removeCharacters(el.id_sl) +
            '' +
            _vm.removeCharacters(el.name),staticClass:"box mb-2 flex"},[_c('div',{staticClass:"w-1 rounded mr-2",style:('background-color:' + _vm.legendColors[el.id_sl].color + ';')}),_c('div',{staticClass:"flex flex-col w-full overflow-hidden"},[_c('div',{staticClass:"text-xl font-bold mb-4"},[_vm._v(_vm._s(el.name))]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-4/9 pr-4 flex items-center"},[_c('div',{staticClass:"text-font-gray text-sm"},[_vm._v("Sales")]),_c('div',{staticClass:"text-xl ml-4"},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(el.total_sales,2))+"€ ")]),_c('div',{staticClass:"text-font-gray text-sm ml-auto"},[_vm._v("vs Obj")]),_c('div',{staticClass:"text-xl ml-4",class:_vm.successOrDangerClass(el.objective_sale, 100)},[(el.objective_sale > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(el.objective_sale,2))),(
                      el.objective_sale != 'N/A' && el.objective_sale != 'TBD'
                    )?_c('span',[_vm._v("%")]):_vm._e()]),_c('div',{staticClass:"text-font-gray text-sm ml-auto"},[_vm._v("vs Avg")]),_c('div',{staticClass:"text-xl ml-4"},[(
                      el.avg_sale > 0 &&
                      el.avg_sale != 'N/A' &&
                      el.avg_sale != 'TBD'
                    )?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(el.avg_sale,2))+" "),(el.avg_sale != 'N/A' && el.avg_sale != 'TBD')?_c('span',[_vm._v("ptos.")]):_vm._e()])]),_c('div',{staticClass:"w-1/3 px-4 flex items-center"},[_c('div',{staticClass:"text-font-gray text-sm"},[_vm._v("Sales Estimated")]),_c('div',{staticClass:"text-xl ml-4"},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(el.estimated_sales,2))+"€ ")]),_c('div',{staticClass:"text-font-gray text-sm ml-auto"},[_vm._v("vs Obj")]),_c('div',{staticClass:"text-xl ml-4"},[(el.objective_estimated_sales > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(el.objective_estimated_sales,2))),(
                      el.objective_estimated_sales != 'N/A' &&
                      el.objective_estimated_sales != 'TBD'
                    )?_c('span',[_vm._v("%")]):_vm._e()])]),_c('div',{staticClass:"flex-none w-2/9 pl-4 flex items-center"},[_c('div',{staticClass:"text-font-gray text-sm mr-4"},[_vm._v(" Effectiveness ratio ")]),_c('div',{staticClass:"text-xl"},[_vm._v(" "+_vm._s(_vm._f("reduceBigNumbers")(el.effectiveness_ratio,2))+"% ")])])])])])}),0):_c('div',{staticClass:"h-full flex flex-col justify-center items-center"},[_c('span',{staticClass:"text-font-gray"},[_vm._v("No SL Selected")])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }