<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="h-12 flex-none flex items-center mb-2" v-if="!mobile">
      <div class="module-title self-center">SMU</div>
      <div class="flex ml-auto">
        <div class="ml-2">
          <div
            @click="toggleSlSelectedItemsAll()"
            class="pill-gray h-full flex flex-col justify-center items-center"
          >
            <span :class="{ 'text-aux': all }">ALL</span>
          </div>
        </div>
        <div class="ml-2" v-for="(pill, k) in legendColors" :key="k">
          <div
            @click="toggleSlSelectedItems(k)"
            class="pill-gray"
            :style="getOpacity(pill, k)"
          >
            {{ pill.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 overflow-hidden relative flex flex-col min-h-0">
      <div
        class="absolute bg-box-80 z-1flex items-center mb-40 h-full w-full top-0 rounded-lg left-0"
        v-if="loading"
      >
        <loader :loading="loading" />
      </div>

      <div v-if="mobile" class="overflow-auto flex-1 pb-8 no-scrollbar">
        <PipelineSMUMobile
          v-for="el in smuFiltered"
          :key="
            el.id +
            '' +
            removeCharacters(el.id_sl) +
            '' +
            removeCharacters(el.name)
          "
          :data="el"
          :desktop="true"
        />

        <div
          v-if="smuFiltered.length == 0"
          class="h-full flex flex-col justify-center items-center"
        >
          <span class="text-font-gray text-xs">No SL Selected</span>
        </div>
      </div>

      <template v-else>
        <transition-group
          name="fade"
          tag="div"
          class="h-full overflow-y-auto pb-10"
          v-if="smuFiltered.length > 0"
        >
          <div
            class="box mb-2 flex"
            :key="
              el.id +
              '' +
              removeCharacters(el.id_sl) +
              '' +
              removeCharacters(el.name)
            "
            v-for="el in smuFiltered"
          >
            <div
              class="w-1 rounded mr-2"
              :style="'background-color:' + legendColors[el.id_sl].color + ';'"
            ></div>
            <div class="flex flex-col w-full overflow-hidden">
              <div class="text-xl font-bold mb-4">{{ el.name }}</div>
              <div class="flex items-center">
                <div class="w-4/9 pr-4 flex items-center">
                  <div class="text-font-gray text-sm">Sales</div>
                  <div class="text-xl ml-4">
                    {{ el.total_sales | reduceBigNumbers(2) }}€
                  </div>
                  <div class="text-font-gray text-sm ml-auto">vs Obj</div>
                  <div
                    class="text-xl ml-4"
                    :class="successOrDangerClass(el.objective_sale, 100)"
                  >
                    <span v-if="el.objective_sale > 0">+</span
                    >{{ el.objective_sale | numberFormat(2)
                    }}<span
                      v-if="
                        el.objective_sale != 'N/A' && el.objective_sale != 'TBD'
                      "
                      >%</span
                    >
                  </div>
                  <div class="text-font-gray text-sm ml-auto">vs Avg</div>
                  <div class="text-xl ml-4">
                    <span
                      v-if="
                        el.avg_sale > 0 &&
                        el.avg_sale != 'N/A' &&
                        el.avg_sale != 'TBD'
                      "
                      >+</span
                    >{{ el.avg_sale | numberFormat(2) }}
                    <span v-if="el.avg_sale != 'N/A' && el.avg_sale != 'TBD'"
                      >ptos.</span
                    >
                  </div>
                </div>
                <div class="w-1/3 px-4 flex items-center">
                  <div class="text-font-gray text-sm">Sales Estimated</div>
                  <div class="text-xl ml-4">
                    {{ el.estimated_sales | reduceBigNumbers(2) }}€
                  </div>
                  <div class="text-font-gray text-sm ml-auto">vs Obj</div>
                  <div class="text-xl ml-4">
                    <span v-if="el.objective_estimated_sales > 0">+</span
                    >{{ el.objective_estimated_sales | numberFormat(2)
                    }}<span
                      v-if="
                        el.objective_estimated_sales != 'N/A' &&
                        el.objective_estimated_sales != 'TBD'
                      "
                      >%</span
                    >
                  </div>
                </div>
                <div class="flex-none w-2/9 pl-4 flex items-center">
                  <div class="text-font-gray text-sm mr-4">
                    Effectiveness ratio
                  </div>
                  <div class="text-xl">
                    {{ el.effectiveness_ratio | reduceBigNumbers(2) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
        <div v-else class="h-full flex flex-col justify-center items-center">
          <span class="text-font-gray">No SL Selected</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { state, actions } from "@/store";
const PipelineSMUMobile = () =>
  import("@/components/Mobile/Pipeline/PipelineSmu");

export default {
  name: "SMUColumn",
  props: ["loadingSl", "sortBy", "sortOrder", "mobile"],
  components: {
    PipelineSMUMobile,
  },
  data() {
    return {
      smu: null,
      loading: false,
      all: false,
    };
  },
  watch: {
    loadingSl() {
      if (!this.loadingSl) {
        this.getSmu();
      }
    },
    params() {
      this.getSmu();
    },
  },
  methods: {
    getSmu() {
      if (!this.params) {
        return;
      }
      this.smu = [];
      this.loading = true;
      this.axios
        .get("pipeline/competency", { params: this.params })
        .then((response) => {
          this.smu = response.data.data;
          this.loading = false;
        });
    },
    getOpacity(pill, k) {
      if (this.slSelectedItems.includes(k)) {
        return (
          "color:" + pill.color + ";background-color:" + pill.color + "25;"
        );
      }
    },
    toggleSlSelectedItems(item) {
      actions.toggleSlSelectedItems(item);
    },
    removeCharacters(str) {
      return str.replace(/[^A-Z0-9]+/gi, "_");
    },
    toggleSlSelectedItemsAll() {
      this.all = !this.all;

      if (state.slSelectedItems.length == 9 || !this.all) {
        actions.clearSlSelectedItems();
      } else {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          actions.addSlSelectedItems(Object.keys(this.legendColors)[index]);
        }
      }
    },
  },
  computed: {
    params() {
      return state.globalParams;
    },
    legendColors() {
      return state.slLegendColors;
    },
    slSelectedItems() {
      return state.slSelectedItems;
    },
    smuFiltered() {
      let arr = [];

      if (this.smu && this.smu.length > 0) {
        arr = this.smu.filter((item) => {
          return this.slSelectedItems.includes(item.id_sl);
        });
      }
      let val = this?.sortBy?.value ?? "name";
      let direction = val == "name" ? "asc" : "desc";

      return _.orderBy(arr, [val], [direction]);
    },
  },
  mounted() {
    this.getSmu();
  },
};
</script>
